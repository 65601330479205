@if (false) {
    <ng-container i18n="@@501_AVG">501 avg.</ng-container>
    <ng-container i18n="@@TUT_501AVERAGE">The 501 average here, is the average over the last 2 months </ng-container>
}

<div class="slide-modal-outer">
    <div class="slide-modal-header">
        <ion-buttons slot="start">
            <div class="slide-modal-close" [translate]="'CANCEL'" i18n="@@CANCEL" (click)="close()">Cancel</div>
        </ion-buttons>
        <div class="slide-modal-title" [translate]="'GAME_DETAILS'" i18n="@@GAME_DETAILS">Game details</div>
    </div>

    <div class="flex flex-1 flex-col overflow-y-auto px-4 gap-4">
        @if (user) {
            <div class="flex flex-col">
                <div class="flex flex-col bg-neutral-100 rounded-md p-5">
                    <div class="flex justify-between items-center overflow-hidden space-x-2">
                        <div class="flex items-center text-black overflow-hidden">
                            <div class="relative">
                                <app-profile-photo
                                    class="h-fit"
                                    [ultimate]="user.is_ultimate"
                                    [badge]="user.badge"
                                    [url]="user.profile_photo_url"
                                    [size]="32">
                                </app-profile-photo>
                            </div>
                            <div class="space-y-0.5 overflow-hidden ml-1">
                                <div class="flex items-center space-x-1">
                                    <div class="ucfirst truncate text-base font-bold leading-none text-black">
                                        {{ user.first_name || ('NO_NAME' | translate) }}
                                    </div>
                                    @if (user.countryFlag) {
                                        <img class="h-3" src="assets/images/flags/rect/{{ user.countryFlag }}" />
                                    }
                                </div>
                                <div class="flex items-center">
                                    <app-online-player-stats
                                        [player]="user"
                                        [reviewCount]="user.times_rated"
                                        size="base"></app-online-player-stats>
                                </div>
                            </div>
                        </div>
                        <div
                            joyrideStep="firstStep"
                            (click)="showTour(0)"
                            title="{{ '501_AVG' | translate }}"
                            text="{{ 'TUT_501AVERAGE' | translate }}"
                            class="font-bebas-neue text-black text-base text-center whitespace-nowrap">
                            <div>{{ '501_AVG' | translate }}</div>
                            <div>{{ user.two_month_average || 0 | number: '1.2-2' }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-4">
                <div class="flex col flex-col bg-neutral-100 rounded-md p-5 relative w-full">
                    <div
                        class="absolute top-1 right-1 rounded-sm p-1 pr-1.5 bg-white text-black font-bebas-neue flex items-center text-lg">
                        <app-icon icon="timer" class="mr-0.5"></app-icon>
                        <div class="flex items-baseline mt-[0.07rem]">
                            <div class="leading-none">{{ onlineGameplay.inGameTimer }}</div>
                            <div class="text-sm leading-none">s</div>
                        </div>
                    </div>
                    <div class="font-bebas-neue text-black text-2xl mb-2">
                        {{ onlineFunctions.onlineGameplayName(onlineGameplay) }}
                    </div>
                    @if (onlineGameplay.type === 'match') {
                        <div
                            class="flex flex-wrap items-center justify-start gap-2 text-black text-xs uppercase font-bold leading-none">
                            @if (onlineFunctions.asMatch(onlineGameplay.game).has_checkout_rate) {
                                <div
                                    class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-fit whitespace-nowrap">
                                    {{ 'CHECKOUT_RATE' | translate }}
                                </div>
                            }
                            <div
                                class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-fit whitespace-nowrap">
                                {{ onlineFunctions.asMatch(onlineGameplay.game).start_with | entryMode }}
                            </div>
                            <div
                                class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-fit whitespace-nowrap">
                                {{ onlineFunctions.asMatch(onlineGameplay.game).end_with | finishMode }}
                            </div>
                            @if (onlineFunctions.asMatch(onlineGameplay.game).two_legs_difference) {
                                <div
                                    class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-fit whitespace-nowrap"
                                    [translate]="'TWO_LEGS_DIFFERENCE'"
                                    i18n="@@TWO_LEGS_DIFFERENCE">
                                    Two legs difference
                                </div>
                            }
                            @if (onlineFunctions.asMatch(onlineGameplay.game).enable_sudden_death) {
                                <div
                                    class="rounded-full p-2 flex items-center bg-white space-x-0.5 h-fit whitespace-nowrap"
                                    [translate]="'SUDDEN_DEATH_MODE'"
                                    i18n="@@SUDDEN_DEATH_MODE">
                                    Sudden death mode
                                </div>
                            }
                        </div>
                    }
                </div>
                <div class="flex flex-col bg-neutral-100 rounded-md p-5 w-full">
                    <div
                        class="font-bebas-neue text-black text-2xl"
                        [translate]="'LAST_FIVE_GAMES'"
                        i18n="@@LAST_FIVE_GAMES">
                        Last results
                    </div>
                    @if (user.last_results?.length > 0) {
                        <div>
                            @for (result of user.last_results; track $index) {
                                <div
                                    class="w-5 h-5 rounded-full mr-1 inline-block"
                                    [ngClass]="{
                                        'bg-red': result === 'lost',
                                        'bg-green': result === 'won',
                                        'bg-blue': result === 'tie'
                                    }"></div>
                            }
                        </div>
                    } @else {
                        <div class="text-black" [translate]="'NO_ONLINE_MATCHES'" i18n="@@NO_ONLINE_MATCHES">
                            No online matches played
                        </div>
                    }
                </div>
            </div>

            @if (user.room?.roomID) {
                <div class="flex flex-col bg-neutral-100 rounded-md w-full p-2">
                    @if (userMedia().videoStreams.hasStreams === false) {
                        <div class="flex w-full items-center justify-center py-2">
                            <app-icon icon="loading" size="extralarge" class="animate-spin text-orange"></app-icon>
                        </div>
                    } @else {
                        @if (userMedia().videoStreams.board) {
                            <div class="relative rounded-md mx-auto max-w-xs basis-1/2 overflow-hidden">
                                @if (userMedia().videoStreams.board.stream) {
                                    <video
                                        class="ingame-camera w-full"
                                        [srcObject]="userMedia().videoStreams.board.stream"
                                        [style.transform]="userMedia().videoStreams.board.scale"
                                        autoplay
                                        playsinline
                                        webkit-playsinline
                                        muted></video>
                                }
                                <div class="absolute left-4 top-4 h-7 w-7 rounded-md bg-orange p-1">
                                    <app-icon icon="videocam" size="medium" class="!text-white"></app-icon>
                                </div>
                            </div>
                        }
                    }
                </div>
            }
        }
    </div>

    @if (!isOwnGame) {
        <div class="flex-none px-4 pt-4 pb-safe-offset-4">
            <app-primary-button
                class="block flex-none"
                addedClasses="w-full"
                size="medium"
                color="orange"
                (click)="join()"
                [translate]="'JOIN'"
                i18n="@@JOIN">
                Join
            </app-primary-button>
        </div>
    }
</div>
