import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { DCFireStoreUser } from '@dc-core/dc-firestore/globals/firestore.tables';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { AlertService } from '@dc-core/dc-services/alert.service';
import { ModalController, Platform } from '@ionic/angular/standalone';
import { IonButtons } from '@ionic/angular/standalone';
import { TranslateModule } from '@ngx-translate/core';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { JoyrideModule } from '@providers/ngx-joyride/src/public-api';
import { Unsubscribe } from 'firebase/auth';
import { DocumentSnapshot } from 'firebase/firestore';
import { Subscription } from 'rxjs';
import { PrimaryButtonComponent } from 'src/app/primary-button/primary-button.component';
import { JanusServerService } from 'src/app/services/janus/janus-server.service';
import { JanusStateService } from 'src/app/services/janus/janus-state.service';
import { JanusSubscriberService } from 'src/app/services/janus/janus-subscriber.service';
import { JanusUserMedia, JanusUserMediaService } from 'src/app/services/janus/janus-user-media.service';

import { EntryModePipe } from '../../../../../components/pipes/entry-mode.pipe';
import { FinishModePipe } from '../../../../../components/pipes/finish-mode.pipe';
import { ProfilePhotoComponent } from '../../../../../components/profile-photo/profile-photo.component';
import { JoyrideService } from '../../../../../providers/ngx-joyride/src/lib/services/joyride.service';
import { OnlinePlayerStatsComponent } from '../../../../online-player-stats/online-player-stats.component';
import { IconComponent } from '../../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-online-lobby-game-details',
    templateUrl: 'online-lobby-game-details.component.html',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        PrimaryButtonComponent,
        ProfilePhotoComponent,
        OnlinePlayerStatsComponent,
        IconComponent,
        EntryModePipe,
        FinishModePipe,
        JoyrideModule,
        IonButtons,
    ],
})
export class OnlineLobbyGameDetailsComponent implements OnInit, OnDestroy {
    @Input() onlineGameplay: OnlineGameplay;
    @Input() user: DCFireStoreUser;
    @Input() isOwnGame: boolean;

    public onlineFunctions: OnlineFunctions = inject(OnlineFunctions);

    private ga: DartCounterAnalyticsService = inject(DartCounterAnalyticsService);
    private joyrideService: JoyrideService = inject(JoyrideService);
    private modalController: ModalController = inject(ModalController);
    private platform: Platform = inject(Platform);
    private lobbySettingsService: LobbySettingsCollectionService = inject(LobbySettingsCollectionService);
    private alertService: AlertService = inject(AlertService);
    private janusStateService: JanusStateService = inject(JanusStateService);
    private janusSubscriberService: JanusSubscriberService = inject(JanusSubscriberService);
    private janusServerService: JanusServerService = inject(JanusServerService);
    private janusUserMediaService: JanusUserMediaService = inject(JanusUserMediaService);

    public userMedia: WritableSignal<JanusUserMedia> = signal(this.janusUserMediaService.getDefaultUserMedia());

    private backButtonSubscription: Subscription;
    private unsubscribeLobbyGame: Unsubscribe;

    public ngOnInit(): void {
        this.ga.trackView('Match | Lobby game preview');

        this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10000, () => {
            this.close();
        });

        this.unsubscribeLobbyGame = this.lobbySettingsService.watchDoc(
            this.onlineGameplay.doc_id,
            (docSnapshot: DocumentSnapshot<OnlineGameplay>) => {
                const gameplay = docSnapshot.data();

                if (!gameplay) {
                    this.alertService.createAlert({
                        icon: 'warning',
                        title: $localize`:@@LOBBY_MATCH_REMOVED:The game is removed from the lobby`,
                    });
                    this.close();
                }
            }
        );

        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.firstTimeProfileDialog)) ?? true;
        if (showTour) {
            this.showTour();
            localStorage.setItem(LocalStorageKey.firstTimeProfileDialog, JSON.stringify(false));
        }

        if (this.user.room?.roomID) {
            if (this.isOwnGame) {
                this.userMedia.set(this.janusStateService.videoUserMedia());
            } else {
                const janusRoom = this.user.room;
                this.janusSubscriberService
                    .spectateRoom(janusRoom, 'video', this.user, this.userMedia, false, true)
                    .catch((error) => {
                        console.error('Error joining room:', error);
                    });
            }
        }
    }

    public close(): void {
        this.unsubscribeLobbyGame();
        this.modalController.dismiss(false);
    }

    public join(): void {
        this.unsubscribeLobbyGame();
        this.modalController.dismiss(true);
    }

    public showTour(index = null): void {
        let steps = ['firstStep'];
        if (index !== null) {
            steps = [steps[index]];
        }

        setTimeout(() => {
            this.joyrideService.startTour({
                steps: steps,
                showPrevButton: false,
                themeColor: '#444',
            });
        }, 500);
    }

    public async ngOnDestroy(): Promise<void> {
        if (this.backButtonSubscription) {
            this.backButtonSubscription.unsubscribe();
        }

        if (this.unsubscribeLobbyGame) {
            this.unsubscribeLobbyGame();
        }

        // If the user had an active room & it's not the own users camera > leave the room
        if (this.user.room?.roomID && !this.isOwnGame) {
            await this.janusStateService
                .leaveRoomAndDetachAllHandles(this.user.room.roomID)
                .catch(console.error)
                .finally(() => {
                    this.janusServerService.destroyUnused();
                });
        }
    }
}
