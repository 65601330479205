/* eslint-disable max-len */
import { Component, computed, inject, Signal, signal, WritableSignal } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseApiService } from '@dc-api/firebase.api.service';
import { GameApiService } from '@dc-api/game.api.service';
import { ReportOrigin } from '@dc-core/dc-backend/dc-enums';
import { OnlineGameplay } from '@dc-core/dc-backend/dc-interfaces';
import { CountsCollectionService } from '@dc-core/dc-firestore/collection-helpers/counts.collection.service';
import { InviteCollectionService } from '@dc-core/dc-firestore/collection-helpers/invite.collection.service';
import { LobbySettingsCollectionService } from '@dc-core/dc-firestore/collection-helpers/lobby_settings.collection.service';
import { PublicGamesCollectionService } from '@dc-core/dc-firestore/collection-helpers/public_games.collection.service';
import { UsersCollectionService } from '@dc-core/dc-firestore/collection-helpers/users.collection.service';
import { FireStoreAuthService } from '@dc-core/dc-firestore/firestore-auth.service';
import {
    DCFireStoreInvite,
    DCFireStoreUser,
    GamesCounts,
    INVITATIONSTATUS,
    INVITATIONTYPE,
} from '@dc-core/dc-firestore/globals/firestore.tables';
import { UnfinishedGamesService } from '@dc-core/dc-firestore/unfinished-games.service';
import { OnlineFunctions } from '@dc-core/dc-gamelogic/online-game/online.functions';
import { OnlineGameHelper } from '@dc-core/dc-helpers/online-game.helper';
import { LocalStorageKey } from '@dc-core/dc-localstorage';
import { RXJSSubscriptionManager } from '@dc-core/dc-logging/rxjs-subscription.manager';
import { SUBSCRIPTION_KEY } from '@dc-core/dc-logging/subscription_enums';
import { AlertPayload, AlertService } from '@dc-core/dc-services/alert.service';
import { CAMERA_TYPE } from '@dc-core/dc-services/camera/camera.models';
import { FreeMatchesService } from '@dc-core/dc-services/free-matches.service';
import { OmniIngameService } from '@dc-core/dc-services/omni/omni-ingame.service';
import { OnlineGamesService } from '@dc-core/dc-services/online-games.service';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular/standalone';
import { DartCounterAnalyticsService } from '@providers/analytics-service';
import { AuthService } from '@services/auth.service';
import { LocalCameraService } from '@services/local-camera.service';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import { debounceTime, Subject, Subscription, take, takeUntil } from 'rxjs';
import { GA_EVENTACTIONS, GA_EVENTCATEGORIES } from 'src/app/app.globals';
import { AppFeaturesService, UltimateFeatureID } from 'src/app/core/app-features/services/app-features.service';
import {
    OnlineGameJoinRequirementsCheckDialogComponent,
    OnlineGameJoinRequirementsCheckDialogPayload,
} from 'src/app/modules/games/dialogs/online-game-join-requirements-check/online-game-join-requirements-check.dialog';
import { UserService } from 'src/app/modules/user/services/user.service';
import { JanusStateService } from 'src/app/services/janus/janus-state.service';
import { UpgradeService } from 'src/app/services/upgrade.service';
import { NewOnlineFeatureDialogComponent } from 'src/dialogs/new-online-feature/new-online-feature';
import { PromptDialogComponent } from 'src/dialogs/prompt/prompt.dialog';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { UltimateDialogPayload } from 'src/dialogs/ultimate-subscription/ultimate-subscription';
import { environment } from 'src/environments/environment';
import { JoyrideService } from 'src/providers/ngx-joyride/src/lib/services/joyride.service';

import { OnlineLobbyGameDetailsComponent } from '../../../../app/modules/games/dialogs/online-lobby-game-details/online-lobby-game-details.component';
import { ProfileDialogComponent } from '../../../../app/modules/profile/dialogs/profile-dialog/profile.dialog';

type LobbySegment = 'lobby' | 'live';

export interface LobbyFilters {
    ultimate: boolean;
    camera: boolean;
    omniScoring: boolean;
    virtCam: boolean;
    threeDartAverage: {
        active: boolean;
        value: { lower: number; upper: number };
    };
    sets: boolean;
}

@Component({
    selector: 'app-global-lobby',
    templateUrl: 'global-lobby.component.html',
})
export class GlobalLobbyComponent {
    private router: Router = inject(Router);

    public segment: LobbySegment = 'lobby';
    public isWeb = environment.isWeb;

    public searchControlPlaceholder: string = $localize`:@@SEARCH:Search`;
    public liveGamesSearchControl: UntypedFormControl = new UntypedFormControl();

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _subscriptionsToDestroy: string[] = [];

    private _backButtonSubscription: Subscription;
    private appFeaturesService: AppFeaturesService = inject(AppFeaturesService);
    private modalController: ModalController = inject(ModalController);

    public minThreeDartAvg = 20;
    public maxThreeDartAvg = 120;

    public showFilters: boolean = false;
    public activeFilters: WritableSignal<LobbyFilters> = signal({
        ultimate: false,
        camera: false,
        omniScoring: false,
        virtCam: false,
        threeDartAverage: {
            active: false,
            value: { lower: this.minThreeDartAvg, upper: this.maxThreeDartAvg },
        },
        sets: false,
    });
    public appliedFilters: WritableSignal<LobbyFilters> = signal(this.activeFilters());
    public hasFilters: Signal<boolean> = computed(
        () =>
            this.appliedFilters().ultimate ||
            this.appliedFilters().camera ||
            this.appliedFilters().omniScoring ||
            this.appliedFilters().virtCam ||
            this.appliedFilters().threeDartAverage.active ||
            this.appliedFilters().sets
    );
    public lobbyGames: Signal<OnlineGameplay[]> = computed(() => {
        const lobbyGames = this.online.lobbyGames();
        const appliedFilters = this.appliedFilters();
        return lobbyGames
            .filter((lobbyGame) =>
                appliedFilters.ultimate ? lobbyGame.ultimateOnly || lobbyGame.players[0].is_ultimate : true
            )
            .filter((lobbyGame) =>
                appliedFilters.camera
                    ? lobbyGame.cameraOnly || lobbyGame.cameraOrOmniScoringOnly || lobbyGame.players[0].room != null
                    : true
            )
            .filter((lobbyGame) =>
                appliedFilters.omniScoring
                    ? lobbyGame.omniScoringOnly || lobbyGame.cameraOrOmniScoringOnly || lobbyGame.players[0].has_omni
                    : true
            )
            .filter((lobbyGame) =>
                appliedFilters.virtCam ? lobbyGame.players[0].room?.camType === CAMERA_TYPE.SMART_DEVICE : true
            )
            .filter((lobbyGame) =>
                appliedFilters.threeDartAverage.active
                    ? (appliedFilters.threeDartAverage.value.lower === this.minThreeDartAvg
                          ? true
                          : lobbyGame.players[0].two_month_average >= appliedFilters.threeDartAverage.value.lower) &&
                      (appliedFilters.threeDartAverage.value.upper === this.maxThreeDartAvg
                          ? true
                          : lobbyGame.players[0].two_month_average <= appliedFilters.threeDartAverage.value.upper)
                    : true
            )
            .filter((lobbyGame) => (appliedFilters.sets ? lobbyGame.game.has_sets : true));
    });
    public hasCurrentGameInLobby: Signal<boolean> = computed(() => {
        return this.lobbySettingsService.currentGameInLobby()
            ? this.lobbyGames().find(
                  (lobbyGame) => lobbyGame.doc_id === this.lobbySettingsService.currentGameInLobby().doc_id
              ) != null
            : false;
    });

    public janusStateService: JanusStateService = inject(JanusStateService);

    constructor(
        public auth: AuthService,
        private nav: NavController,
        public route: ActivatedRoute,
        private ga: DartCounterAnalyticsService,
        private platform: Platform,
        private upgrade: UpgradeService,
        private _alertService: AlertService,
        private joyrideService: JoyrideService,
        public _gameApiService: GameApiService,
        public onlineFunctions: OnlineFunctions,
        public freeMatchesService: FreeMatchesService,
        public lobbySettingsService: LobbySettingsCollectionService,
        private _dcFireAuth: FireStoreAuthService,
        private _usersCollectionService: UsersCollectionService,
        private menu: MenuController,
        public dcInvites: InviteCollectionService,
        private _firebaseApiService: FirebaseApiService,
        private _rxjsSubscriptionManager: RXJSSubscriptionManager,
        public unfinishedGamesService: UnfinishedGamesService,
        public online: OnlineGamesService,
        private _publicGamesCollection: PublicGamesCollectionService,
        private _countsCollectionService: CountsCollectionService,
        public omniIngameService: OmniIngameService,
        private userService: UserService,
        private _localCameraService: LocalCameraService
    ) {
        const showTour = JSON.parse(localStorage.getItem(LocalStorageKey.globalTutorial)) ?? true;
        if (showTour) {
            this.showTour();
        } else {
            this.joyrideDone();
        }
    }

    ionViewWillEnter(): void {
        this.ga.trackView('Global mode');
        this.online.start(this.auth.user);

        if (!environment.isWeb) {
            this.menu.enable(false, 'mainmenu');
        }
        this.menu.enable(false, 'ingamemenu');

        this.route.queryParams.pipe(take(1)).subscribe(async (params) => {
            if (params.segment != null) {
                this.changeSegment(params.segment);
            } else {
                this.changeSegment(this.segment);
            }
        });

        this._backButtonSubscription = this.platform.backButton.subscribeWithPriority(9999, () => {
            this.goBack();
        });

        this.liveGamesSearchControl.valueChanges
            .pipe(debounceTime(600), takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                if (value.length) {
                    this.online.searchLiveGames(value);
                } else {
                    this.loadPublicGames();
                }
            });

        if (localStorage.getItem(LocalStorageKey.globalLobbyFilters)) {
            const globalLobbyFilters = JSON.parse(
                localStorage.getItem(LocalStorageKey.globalLobbyFilters)
            ) as LobbyFilters;
            this.activeFilters.update((activeFilters) => ({
                ultimate: globalLobbyFilters.ultimate ?? activeFilters.ultimate,
                camera: globalLobbyFilters.camera ?? activeFilters.camera,
                omniScoring: globalLobbyFilters.omniScoring ?? activeFilters.omniScoring,
                virtCam: globalLobbyFilters.virtCam ?? activeFilters.virtCam,
                threeDartAverage: globalLobbyFilters.threeDartAverage ?? activeFilters.threeDartAverage,
                sets: globalLobbyFilters.sets ?? activeFilters.sets,
            }));
            this.appliedFilters.set({ ...this.activeFilters() });
        }
    }

    showTour() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWTOUR);
        setTimeout(() => {
            this.joyrideService.startTour({
                steps: ['global1'],
                showPrevButton: false,
                themeColor: '#444',
            });

            localStorage.setItem(LocalStorageKey.globalTutorial, JSON.stringify(false));
        }, 1500);
    }

    async joyrideDone() {
        if (!this.appFeaturesService.enabledAppFeatures().action_replays) {
            return;
        }

        const newOnlineFeatureKey = 'action-replays';

        const newOnlineFeature = JSON.parse(localStorage.getItem(LocalStorageKey.newOnlineFeatureDialog));

        if (newOnlineFeature != newOnlineFeatureKey) {
            this.modalController
                .create({
                    component: NewOnlineFeatureDialogComponent,
                    cssClass: 'auto-height',
                    backdropDismiss: true,
                    showBackdrop: true,
                })
                .then((elem) => {
                    elem.present();
                    elem.onDidDismiss().then(() => {
                        localStorage.setItem(
                            LocalStorageKey.newOnlineFeatureDialog,
                            JSON.stringify(newOnlineFeatureKey)
                        );
                    });
                });
        }
    }

    goBack(): void {
        this.online.controlSmartDeviceInactivity(true, false, false);
        this.nav.navigateRoot('online-games', { animated: true });
    }

    public checkInviteStatus(invite: DCFireStoreInvite) {
        switch (invite.status) {
            case INVITATIONSTATUS.ACCEPTED:
                this._alertService.createAlert({
                    title: $localize`:@@INVITE_ACCEPTED_JOINING_NOW:Your invite has been accepted!`,
                });
                this.removeOutgoingInvite();
                break;
            case INVITATIONSTATUS.DECLINED:
                this._alertService.createAlert({
                    icon: 'warning',
                    title: $localize`:@@INVITE_DECLINED:Your invite has been declined!`,
                });
                this.removeOutgoingInvite();
                break;
        }
    }

    public removeOutgoingInvite() {
        this.dcInvites.removeOutgoingInvites();
    }

    isBlocked(id: number) {
        for (const blockedUser of this.online.blockedUsers) {
            if (blockedUser.id == id) {
                return true;
            }
        }
        return false;
    }

    trackByFn(index: number, item: any): any {
        return item.doc_id || item.uid || index;
    }

    showPlayerInfo(user: DCFireStoreUser) {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWPROFILEPOPUP);

        this.modalController
            .create({
                component: ProfileDialogComponent,
                componentProps: {
                    user: this.onlineFunctions.fsUserToOnlineUser(user),
                    canReport: this.auth.user.id != user.user_id,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'report') {
                        this.modalController
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user: this.onlineFunctions.fsUserToOnlineUser(user),
                                    origin: 'global_lobby' as ReportOrigin,
                                },
                            })
                            .then((elem) => {
                                elem.present();
                            });
                    }
                });
            });
    }

    async openUpgradeDialog() {
        const dialogComponent = await this.upgrade.getUpgradeDialog(GA_EVENTCATEGORIES.GLOBALLOBBY);
        this.modalController
            .create({
                component: dialogComponent,
                cssClass: environment.isWeb ? ['ion-fullscreen-modal', 'web'] : 'ion-fullscreen-modal',
            })
            .then((elem) => {
                elem.present();
            });
    }

    changeSegment(segment: LobbySegment) {
        this.segment = segment;

        void this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { segment: segment },
            queryParamsHandling: 'merge',
        });

        if (segment === 'lobby') {
            this.clearLiveGames();
        } else if (segment === 'live') {
            this.loadPublicGames();
        }
    }

    loadPublicGames(event = null): void {
        if (event == null) {
            this.clearLiveGames();

            this._countsCollectionService.getGamesCounts().then((gamesCount: GamesCounts) => {
                this.online.liveGamesCount = gamesCount.publicGames;
            });

            const publicMatchesSub = this._publicGamesCollection.publicGames$.subscribe((liveGames) => {
                if (this.online.liveGames === null) {
                    this.online.liveGames = [];
                }
                this.online.liveGames = [...this.online.liveGames, ...liveGames];

                // Check the Janus Serverload in Live Lobby
                // this.checkJanusServerLoad();
            });

            this._rxjsSubscriptionManager.addSubscription(
                'globallobby',
                SUBSCRIPTION_KEY.PUBLIC_GAMES,
                publicMatchesSub
            );
            // Auto unwatch onDestroy
            this._subscriptionsToDestroy.push(SUBSCRIPTION_KEY.PUBLIC_GAMES);
        }

        //Load the actual games
        this._publicGamesCollection.fetchGames(this.online.liveGamesTake);

        if (event) {
            event.target.complete();
        }
    }

    checkJanusServerLoad() {
        const hostCounts = this.online.liveGames.reduce(
            (acc, game) => {
                game.players.forEach((player) => {
                    if (player.room?.janusServerHost) {
                        const host = player.room.janusServerHost;
                        acc[host] = (acc[host] || 0) + 1;
                    }
                });
                return acc;
            },
            {} as Record<string, number>
        );

        console.log(hostCounts);
    }

    clearLiveGames(): void {
        this._rxjsSubscriptionManager.cleanSubscription(SUBSCRIPTION_KEY.PUBLIC_GAMES);
        this.online.liveGames = null;
        this._publicGamesCollection.clearFetchedGames();
    }

    createGame() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.OPENONLINESETUP);
        this.nav.navigateForward('online-setup');
    }

    showUnfinished() {
        this.nav.navigateForward('online/unfinished-games');
    }

    addToLobby(): void {
        if (!this.freeMatchesService.canPlay) {
            this._alertService.createAlert({
                icon: 'warning',
                title: $localize`:@@NO_MORE_FREE_GAMES_THIS_WEEK:Your free online games for this week have been used`,
            });
        } else if (
            _.find(this.online.lobbyGames(), (game) => game.players[0].uid == this._dcFireAuth.getCurrentUID())
        ) {
            this._alertService.createAlert({
                icon: 'warning',
                title: $localize`:@@ALREADY_HAVE_GAME_IN_LOBBY:You already have a game in the lobby`,
            });
        } else {
            this.online.setLobbyGame();
        }
    }

    removeFromLobby(): void {
        this.lobbySettingsService.removeOwnItems();
        this.dcInvites.removeIncomingInvites();
    }

    toggleOwnGame(): void {
        this.userService
            .canPlayOnline(true, true)
            .pipe(take(1))
            .subscribe((valid: boolean) => {
                if (valid) {
                    if (this.lobbySettingsService.currentGameInLobby()) {
                        this.removeFromLobby();
                    } else {
                        if (this.online.onlineSavedGameplay) {
                            if (
                                this.online.onlineSavedGameplay?.cameraOnly &&
                                !this.janusStateService.videoRoom()?.roomID
                            ) {
                                this._alertService.createAlert({
                                    title: $localize`:@@ACTIVATE_CAMERA_TO_CONTINUE:Activate camera to continue`,
                                    text: $localize`:@@NEED_ACTIVATED_CAM_BECAUSE_JOIN_REQUIREMENT:Need activated camera because cam only requirement is enabled`,
                                    icon: 'error',
                                    timer: null,
                                    showCloseButton: true,
                                    confirmButtonText: $localize`:@@ACTIVATE_CAMERA:Activate camera`,
                                    onConfirm: () => {
                                        this._localCameraService.linkCamera(false);
                                    },
                                });
                            } else {
                                this.addToLobby();
                            }
                        } else {
                            this.online.addGameAfterCreate = true;
                            this.createGame();
                        }
                    }
                }
            });
    }

    async showGameInfo(gameplay: OnlineGameplay) {
        this.userService
            .canPlayOnline(true, true)
            .pipe(take(1))
            .subscribe(async (valid: boolean) => {
                if (valid) {
                    this.online.controlSmartDeviceInactivity(false, false, false);

                    this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.SHOWMATCHINFO);
                    if (!this.auth.user.is_ultimate) {
                        if (!this.freeMatchesService.canPlay) {
                            this._alertService.createAlert({
                                title: this.freeMatchesService.freeOnlineMatchesMessage,
                                icon: 'error',
                            });
                        } else if (gameplay.ultimateOnly) {
                            this.modalController
                                .create({
                                    component: PromptDialogComponent,
                                    componentProps: {
                                        title: $localize`:@@THIS_IS_AN_ULTIMATE_ONLY_GAME:This is an Ultimate-only game`,
                                        icon: 'ultimate',
                                        confirmText: $localize`:@@UPGRADE_TO_ULTIMATE:Upgrade to Ultimate`,
                                        confirmColor: 'blue',
                                        showCloseButton: true,
                                    } as AlertPayload,
                                    cssClass: 'auto-height',
                                    showBackdrop: true,
                                    backdropDismiss: false,
                                })
                                .then((elem) => {
                                    elem.present();
                                    elem.onDidDismiss().then(async (dialogRes) => {
                                        if (dialogRes.data) {
                                            const dialogComponent = await this.upgrade.getUpgradeDialog(
                                                GA_EVENTCATEGORIES.STATISTICS
                                            );
                                            this.modalController
                                                .create({
                                                    component: dialogComponent,
                                                    componentProps: {
                                                        highlightedFeatureID: UltimateFeatureID.PLAY_ONLINE,
                                                    } as UltimateDialogPayload,
                                                    cssClass: environment.isWeb
                                                        ? ['ion-fullscreen-modal', 'web']
                                                        : 'ion-fullscreen-modal',
                                                })
                                                .then((elem) => {
                                                    elem.present();
                                                });
                                        }
                                    });
                                });
                        } else {
                            this.checkGameplaySettingsAndOpenGameInfo(gameplay);
                        }
                    } else {
                        this.checkGameplaySettingsAndOpenGameInfo(gameplay);
                    }
                }
            });
    }

    openGameInfo(gameplay: OnlineGameplay): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.JOINMATCH);

        const user = gameplay.players[0];
        this.modalController
            .create({
                component: OnlineLobbyGameDetailsComponent,
                componentProps: {
                    onlineGameplay: gameplay,
                    user: user,
                    isOwnGame: user.user_id === this.auth.user.id,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                showBackdrop: true,
                backdropDismiss: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data == true) {
                        this.tryJoinGame(gameplay);
                    }
                });
            });
    }

    cancelJoin() {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.CANCELJOINMATCH);
        // Show Dialog, do you want to invite your opponent to continue this match?
        this.modalController
            .create({
                component: PromptDialogComponent,
                componentProps: {
                    title: $localize`:@@CANCEL_INVITE:Cancel invite`,
                    text: $localize`:@@CANCEL_GAMEINVITE_QUESTION:Do you want to cancel this invite?`,
                    cancelText: $localize`:@@CANCEL:Cancel`,
                    confirmText: $localize`:@@CONFIRM:Confirm`,
                    confirmColor: 'orange',
                } as AlertPayload,
                cssClass: 'auto-height',
                showBackdrop: true,
                backdropDismiss: false,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data) {
                        this.dcInvites.removeOutgoingInvites();
                    }
                });
            });
    }

    watch(gameplay: OnlineGameplay): void {
        this.online.stop();
        this.nav.navigateForward(OnlineGameHelper.getGameRoute(gameplay.type, gameplay.doc_id, true));
    }

    public saveFilters(): void {
        this.ga.trackEvent(GA_EVENTCATEGORIES.GLOBALLOBBY, GA_EVENTACTIONS.FILTER);
        this.appliedFilters.set({ ...this.activeFilters() });
        localStorage.setItem(LocalStorageKey.globalLobbyFilters, JSON.stringify(this.appliedFilters()));
    }

    public clearFilters(): void {
        this.activeFilters.set({
            ultimate: false,
            camera: false,
            omniScoring: false,
            virtCam: false,
            threeDartAverage: {
                active: false,
                value: { lower: this.minThreeDartAvg, upper: this.maxThreeDartAvg },
            },
            sets: false,
        });
        this.saveFilters();
    }

    private checkGameplaySettingsAndOpenGameInfo(gameplay: OnlineGameplay): void {
        if (gameplay.cameraOnly || gameplay.omniScoringOnly || gameplay.cameraOrOmniScoringOnly) {
            const cameraEnabled =
                this.janusStateService.videoRoom().roomID &&
                this.janusStateService.videoUserMedia()?.videoStreams?.hasStreams &&
                this.janusStateService.videoUserMedia()?.videoStreams?.activeStreams === true;

            const omniEnabled = this.omniIngameService.omniConnected();

            let readyToJoin = true;

            if (gameplay.cameraOrOmniScoringOnly && !cameraEnabled && !omniEnabled) {
                readyToJoin = false;
            } else {
                if (gameplay.cameraOnly && !cameraEnabled) {
                    readyToJoin = false;
                }
                if (gameplay.omniScoringOnly && !omniEnabled) {
                    readyToJoin = false;
                }
            }

            // User met the given requirements > no further dialog needed
            if (readyToJoin) {
                this.openGameInfo(gameplay);
            } else {
                this.modalController
                    .create({
                        component: OnlineGameJoinRequirementsCheckDialogComponent,
                        componentProps: {
                            cameraOnly: gameplay.cameraOnly,
                            omniScoringOnly: gameplay.omniScoringOnly,
                            cameraOrOmniScoringOnly: gameplay.cameraOrOmniScoringOnly,
                        } as OnlineGameJoinRequirementsCheckDialogPayload,
                        cssClass: 'auto-height',
                        showBackdrop: true,
                        backdropDismiss: false,
                    })
                    .then((elem) => {
                        elem.present();
                        elem.onDidDismiss().then((dialogRes) => {
                            if (dialogRes.data) {
                                this.openGameInfo(gameplay);
                            }
                        });
                    });
            }
        } else {
            this.openGameInfo(gameplay);
        }
    }

    private tryJoinGame(gameplay: OnlineGameplay) {
        gameplay.name = this.onlineFunctions.onlineGameplayName(gameplay);
        const fsUser = this._usersCollectionService.getDCFireStoreUser(this.auth.user);

        const invite = {
            sender_uid: fsUser.uid,
            receiver_uid: gameplay.owners[0],
            gameplay_doc_id: gameplay.doc_id,
            invitation_type: INVITATIONTYPE.JOIN,
            sent_at: Timestamp.now(),
            status: INVITATIONSTATUS.PENDING,
            user: fsUser,
        } as DCFireStoreInvite;

        this.dcInvites.sendFirestoreInvite(invite);
        this._firebaseApiService.firebaseJoinGame({ user_ids: [gameplay.players[0].user_id] });
    }

    ionViewWillLeave(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Unsubscribe from all subscriptions
        this._subscriptionsToDestroy.forEach((key) => {
            this._rxjsSubscriptionManager.cleanSubscription(key);
        });

        this.freeMatchesService.clear();

        if (this._backButtonSubscription) {
            this._backButtonSubscription.unsubscribe();
        }
    }
}
