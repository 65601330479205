@if (false) {
    <ng-container i18n="@@SEARCH">Search</ng-container>
    <ng-container i18n="@@CAM_TUTORIAL_TITLE">Link your camera</ng-container>
    <ng-container i18n="@@CAM_TUTORIAL_TEXT">Tap here to play online with camera</ng-container>
}

<app-page-header mainNavigation="custom" defaultBack="online-games">
    <div left class="flex items-center cursor-pointer -ml-2" (click)="goBack()">
        <app-icon icon="keyboard_arrow_left" size="large"></app-icon>
        <div class="text-center text-sm font-bold !text-white" [translate]="'ONLINE'" i18n="@@ONLINE">Online</div>
    </div>
    <div title [translate]="'GLOBAL_MODE'" i18n="@@GLOBAL_MODE">Global lobby</div>
    <div right class="right-page-header">
        <app-control-camera-icon [refreshGame]="true"></app-control-camera-icon>
        @if (isWeb) {
            <app-fullscreen-icon></app-fullscreen-icon>
        }
    </div>
</app-page-header>

<div class="relative flex h-full flex-col overflow-hidden bg-white-pattern text-black" id="main-content">
    <div class="tab-toggle-container">
        <div
            class="tab-toggle"
            [class.inner-border-b-2-orange]="segment === 'lobby'"
            [class.opacity-60]="segment !== 'lobby'"
            (click)="changeSegment('lobby')">
            <div [translate]="'LOBBY'" i18n="@@LOBBY">Lobby</div>
        </div>
        <div
            class="tab-toggle"
            [class.inner-border-b-2-orange]="segment === 'live'"
            [class.opacity-60]="segment !== 'live'"
            (click)="changeSegment('live')">
            <div [translate]="'LIVE_GAMES'" i18n="@@LIVE_GAMES">Live games</div>
        </div>
    </div>

    <div class="flex flex-auto flex-col overflow-auto overscroll-none landscape:px-safe">
        @if (segment === 'lobby') {
            <div class="flex flex-col py-4 space-y-4">
                <div class="flex justify-between items-center text-black flex-none px-4">
                    <div class="flex items-center font-bebas-neue text-xl">
                        <div class="flex items-center mr-1">
                            <div>(</div>
                            <div class="text-orange">{{ lobbyGames().length }}</div>
                            <div>)</div>
                        </div>

                        @if (!hasFilters()) {
                            <div [translate]="'GAMES_IN_LOBBY'" i18n="@@GAMES_IN_LOBBY">Games in lobby</div>
                        } @else {
                            <div [translate]="'FILTERED_GAMES'" i18n="@@FILTERED_GAMES">Filtered games</div>
                        }
                    </div>
                    <div class="flex items-center space-x-2">
                        <app-icon
                            icon="tune"
                            size="large"
                            class="text-black cursor-pointer"
                            [class.!text-orange]="showFilters"
                            (click)="showFilters = !showFilters"></app-icon>
                    </div>
                </div>

                @if (showFilters) {
                    <div class="new-game-card light-dark px-4 mx-4">
                        <div class="title-container">
                            <div class="icon">
                                <app-icon icon="tune" size="small"></app-icon>
                            </div>
                            <div class="title" [translate]="'FILTERS'" i18n="@@FILTERS">Filters</div>
                            <app-primary-button
                                class="ml-auto"
                                size="extrasmall"
                                addedSlotClasses="!py-1.5"
                                color="white"
                                [translate]="'CLEAR_FILTERS'"
                                i18n="@@CLEAR_FILTERS"
                                (click)="clearFilters()">
                                Clear filters
                            </app-primary-button>
                        </div>

                        <div class="flex flex-col xl:flex-row justify-center space-y-4 xl:space-y-0 xl:space-x-4">
                            <div class="flex justify-center gap-4">
                                <div
                                    class="global-lobby-filter"
                                    [class]="activeFilters().ultimate ? 'active' : 'not-active'"
                                    (click)="activeFilters().ultimate = !activeFilters().ultimate; saveFilters()">
                                    <div class="top">
                                        <app-icon icon="premium" size="small"></app-icon>
                                    </div>
                                    <div class="bottom">
                                        <div
                                            class="text-lg font-bebas-neue leading-none"
                                            [translate]="'ULTIMATE'"
                                            i18n="@@ULTIMATE">
                                            Ultimate
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="global-lobby-filter"
                                    [class]="activeFilters().camera ? 'active' : 'not-active'"
                                    (click)="activeFilters().camera = !activeFilters().camera; saveFilters()">
                                    <div class="top">
                                        <app-icon icon="videocam" size="medium"></app-icon>
                                    </div>
                                    <div class="bottom">
                                        <div
                                            class="text-lg font-bebas-neue leading-none"
                                            [translate]="'CAMERA'"
                                            i18n="@@CAMERA">
                                            Camera
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="global-lobby-filter"
                                    [class]="activeFilters().omniScoring ? 'active' : 'not-active'"
                                    (click)="activeFilters().omniScoring = !activeFilters().omniScoring; saveFilters()">
                                    <div class="top">
                                        <img class="w-12" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                                    </div>
                                    <div class="bottom">
                                        <div class="text-lg font-bebas-neue leading-none">Omni</div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-center gap-4">
                                <div
                                    class="global-lobby-filter"
                                    [class]="activeFilters().virtCam ? 'active' : 'not-active'"
                                    (click)="activeFilters().virtCam = !activeFilters().virtCam; saveFilters()">
                                    <div class="top">
                                        <img class="w-12" [src]="'assets/icon/ftr/virt_cam_logo.webp'" />
                                    </div>
                                    <div class="bottom">
                                        <div class="text-lg font-bebas-neue leading-none">Virt Cam</div>
                                    </div>
                                </div>

                                <div
                                    class="global-lobby-filter"
                                    [class]="activeFilters().threeDartAverage.active ? 'active' : 'not-active'"
                                    (click)="
                                        activeFilters().threeDartAverage.active =
                                            !activeFilters().threeDartAverage.active;
                                        saveFilters()
                                    ">
                                    <div class="top">
                                        <app-icon icon="three_dots" size="small"></app-icon>
                                    </div>
                                    <div class="bottom">
                                        <div
                                            class="text-lg font-bebas-neue leading-none"
                                            [translate]="'AVERAGE'"
                                            i18n="@@AVERAGE">
                                            3-dart avg.
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="global-lobby-filter"
                                    [class]="activeFilters().sets ? 'active' : 'not-active'"
                                    (click)="activeFilters().sets = !activeFilters().sets; saveFilters()">
                                    <div class="top">
                                        <app-match-interim-score size="small" type="sets">S</app-match-interim-score>
                                    </div>
                                    <div class="bottom">
                                        <div
                                            class="text-lg font-bebas-neue leading-none"
                                            [translate]="'SETS'"
                                            i18n="@@SETS">
                                            Sets
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        @if (activeFilters().threeDartAverage.active) {
                            <div class="mt-3" [class.max-w-sm]="isWeb" [class.mx-auto]="isWeb">
                                <ion-range
                                    mode="md"
                                    [min]="minThreeDartAvg"
                                    class="!py-0"
                                    [max]="maxThreeDartAvg"
                                    step="5"
                                    name="specificThreeDartAvg"
                                    ngDefaultControl
                                    [snaps]="true"
                                    [ticks]="true"
                                    [dualKnobs]="true"
                                    [value]="activeFilters().threeDartAverage.value"
                                    [(ngModel)]="activeFilters().threeDartAverage.value"
                                    (ionChange)="saveFilters()"
                                    color="secondary">
                                </ion-range>

                                <div class="flex justify-between items-center font-bebas-neue text-lg">
                                    @if (activeFilters().threeDartAverage.value.lower === minThreeDartAvg) {
                                        <div [translate]="'NO_MINIMUM'" i18n="@@NO_MINIMUM">No minimum</div>
                                    } @else {
                                        <div class="flex items-center space-x-1">
                                            <div [translate]="'MINIMUM'" i18n="@@MINIMUM">Minimum</div>
                                            <div>{{ activeFilters().threeDartAverage.value.lower }}</div>
                                        </div>
                                    }

                                    @if (activeFilters().threeDartAverage.value.upper === maxThreeDartAvg) {
                                        <div [translate]="'NO_MAXIMUM'" i18n="@@NO_MAXIMUM">No maximum</div>
                                    } @else {
                                        <div class="flex items-center space-x-1">
                                            <div [translate]="'MAXIMUM'" i18n="@@MAXIMUM">Maximum</div>
                                            <div>{{ activeFilters().threeDartAverage.value.upper }}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                } @else if (hasFilters()) {
                    <div class="overflow-x-scroll scrollbar-hide flex items-center gap-1 px-4">
                        @if (activeFilters().ultimate) {
                            <div class="global-lobby-filter-badge">
                                <app-icon class="scale-[0.80]" icon="premium" size="small"></app-icon>
                                <div class="mb-[2px]" [translate]="'ULTIMATE'" i18n="@@ULTIMATE">Ultimate</div>
                                <app-icon
                                    class="!ml-1.5 text-neutral-400 w-fit cursor-pointer"
                                    icon="close"
                                    size="small"
                                    (click)="activeFilters().ultimate = false; saveFilters()"></app-icon>
                            </div>
                        }
                        @if (activeFilters().camera) {
                            <div class="global-lobby-filter-badge">
                                <app-icon icon="videocam" size="small"></app-icon>
                                <div class="mb-[2px]" [translate]="'CAMERA'" i18n="@@CAMERA">Camera</div>
                                <app-icon
                                    class="!ml-1.5 text-neutral-400 w-fit cursor-pointer"
                                    icon="close"
                                    size="small"
                                    (click)="activeFilters().camera = false; saveFilters()"></app-icon>
                            </div>
                        }
                        @if (activeFilters().omniScoring) {
                            <div class="global-lobby-filter-badge">
                                <img class="w-12" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                                <app-icon
                                    class="!ml-1.5 text-neutral-400 w-fit cursor-pointer"
                                    icon="close"
                                    size="small"
                                    (click)="activeFilters().omniScoring = false; saveFilters()"></app-icon>
                            </div>
                        }
                        @if (activeFilters().virtCam) {
                            <div class="global-lobby-filter-badge">
                                <img class="w-12" [src]="'assets/icon/ftr/virt_cam_logo.webp'" />
                                <app-icon
                                    class="!ml-1.5 text-neutral-400 w-fit cursor-pointer"
                                    icon="close"
                                    size="small"
                                    (click)="activeFilters().virtCam = false; saveFilters()"></app-icon>
                            </div>
                        }
                        @if (activeFilters().threeDartAverage.active) {
                            <div class="global-lobby-filter-badge">
                                <app-icon icon="three_dots" size="small"></app-icon>
                                <div class="mb-[2px]" [translate]="'AVERAGE'" i18n="@@AVERAGE">3-dart avg.</div>
                                <app-icon
                                    class="!ml-1.5 text-neutral-400 w-fit cursor-pointer"
                                    icon="close"
                                    size="small"
                                    (click)="activeFilters().threeDartAverage.active = false; saveFilters()"></app-icon>
                            </div>
                        }
                        @if (activeFilters().sets) {
                            <div class="global-lobby-filter-badge">
                                <app-match-interim-score class="px-0.5" size="small" type="sets">
                                    S
                                </app-match-interim-score>
                                <div class="mb-[2px]" [translate]="'SETS'" i18n="@@SETS">Sets</div>
                                <app-icon
                                    class="!ml-1.5 text-neutral-400 w-fit cursor-pointer"
                                    icon="close"
                                    size="small"
                                    (click)="activeFilters().sets = false; saveFilters()"></app-icon>
                            </div>
                        }
                    </div>
                }

                @if (!online.loaded()) {
                    <div class="flex justify-center">
                        <app-icon icon="loading" size="large" class="mx-auto animate-spin text-orange"></app-icon>
                    </div>
                }

                @if (!lobbyGames().length && online.loaded()) {
                    @if (!hasFilters()) {
                        <div
                            class="py-4 text-center font-bebas-neue text-xl text-black"
                            [translate]="'NO_GAMES_IN_LOBBY'"
                            i18n="@@NO_GAMES_IN_LOBBY">
                            No games in lobby
                        </div>
                    } @else {
                        <div
                            class="py-4 text-center font-bebas-neue text-xl text-black"
                            [translate]="'NO_GAMES_FOUND_BASED_ON_YOUR_FILTERS'"
                            i18n="@@NO_GAMES_FOUND_BASED_ON_YOUR_FILTERS">
                            No games found based on your filters
                        </div>
                    }
                }

                <!-- Lobby -->
                <div class="grid grid-cols-1 gap-4 px-4 md:grid-cols-2 xl:grid-cols-3">
                    @if (lobbySettingsService.currentGameInLobby()) {
                        <ng-container
                            *ngTemplateOutlet="
                                lobbyGame;
                                context: { gameplay: lobbySettingsService.currentGameInLobby(), yourGame: true }
                            "></ng-container>
                    }

                    @for (gameplay of lobbyGames(); track $index) {
                        @if (
                            !online.isBlocked(gameplay.players[0].user_id) &&
                            gameplay.players[0].user_id !== online.user?.id
                        ) {
                            <ng-container
                                *ngTemplateOutlet="lobbyGame; context: { gameplay, yourGame: false }"></ng-container>
                        }
                    }
                </div>
            </div>
        } @else if (segment === 'live') {
            <!-- Live games -->
            <ion-content
                style="
                    --padding-start: 1rem !important;
                    --padding-end: 1rem !important;
                    --padding-top: 1rem !important;
                    --padding-bottom: 1rem !important;
                    --background: none !important;
                ">
                @if (online.loaded()) {
                    <div class="dialog-input with-icon mb-4">
                        <app-icon icon="search"></app-icon>
                        <input
                            type="text"
                            [formControl]="liveGamesSearchControl"
                            [placeholder]="searchControlPlaceholder" />
                    </div>
                }

                @if (!online.loaded() || online.liveGames === null) {
                    <div class="col-span-1 flex justify-center md:col-span-2">
                        <app-icon icon="loading" size="normal" class="mx-auto animate-spin text-orange"></app-icon>
                    </div>
                }
                @if (online.liveGames?.length === 0 && online.loaded()) {
                    <div
                        class="py-4 text-center font-bebas-neue text-xl text-black"
                        [translate]="'NO_LIVE_GAMES'"
                        i18n="@@NO_LIVE_GAMES">
                        No live games
                    </div>
                }
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                    @if (online.liveGames !== null && online.loaded()) {
                        @for (gameplay of online.liveGames; track $index) {
                            <ng-container *ngTemplateOutlet="liveGame; context: { gameplay }"></ng-container>
                        }
                    }
                </div>

                @if (online.liveGames !== null && online.loaded()) {
                    <ion-infinite-scroll [threshold]="'20%'" (ionInfinite)="loadPublicGames($event)">
                        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="{{ 'LOADING' | translate }}">
                        </ion-infinite-scroll-content>
                    </ion-infinite-scroll>
                }
            </ion-content>
        }
    </div>

    <div class="flex-none" [class.hidden]="segment !== 'lobby'">
        <div
            class="landscape:px-safe !bg-black"
            [class.pb-safe]="!online.onlineSavedGameplay && online.user?.is_ultimate">
            <app-online-saved-gameplay class="block w-full"></app-online-saved-gameplay>
        </div>
        @if (online.user?.is_ultimate || (!online.user?.is_ultimate && freeMatchesService.canPlay)) {
            @if (online.onlineSavedGameplay) {
                <app-toggle-switch
                    class="block w-full bg-panel py-3 px-safe-offset-4 text-black"
                    [class.pb-safe-offset-3]="online.user?.is_ultimate"
                    [model]="online.showGameInLobby"
                    (itemChange)="toggleOwnGame()">
                    <div class="flex gap-2">
                        <div [translate]="'ADD_YOUR_GAME_IN_LOBBY'" i18n="@@ADD_YOUR_GAME_IN_LOBBY">
                            Add your game to lobby
                        </div>
                        @if (
                            online.onlineSavedGameplay.cameraOnly ||
                            online.onlineSavedGameplay.ultimateOnly ||
                            online.onlineSavedGameplay.omniScoringOnly
                        ) {
                            <div class="flex items-center rounded-sm bg-neutral-50 text-black px-1">
                                <div
                                    class="text-xxxs font-bold uppercase leading-none px-0.5"
                                    [translate]="'ONLY'"
                                    i18n="@@ONLY">
                                    Only
                                </div>

                                @if (online.onlineSavedGameplay.ultimateOnly) {
                                    <app-icon class="scale-[0.80]" icon="premium" size="small"></app-icon>
                                }

                                @if (online.onlineSavedGameplay.cameraOnly) {
                                    <app-icon class="px-0.5" icon="videocam" size="small"></app-icon>
                                }

                                @if (online.onlineSavedGameplay.omniScoringOnly) {
                                    <img class="w-12 px-0.5" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                                }
                            </div>
                        }

                        @if (online.onlineSavedGameplay.cameraOrOmniScoringOnly) {
                            <div class="flex items-center rounded-sm bg-neutral-50 text-black px-1">
                                <app-icon class="px-0.5" icon="videocam" size="small"></app-icon>
                                <div
                                    class="text-xxxs font-bold uppercase leading-none mr-0.5"
                                    [translate]="'OR'"
                                    i18n="@@OR">
                                    Or
                                </div>
                                <img class="w-12 px-0.5" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                            </div>
                        }
                    </div>
                </app-toggle-switch>
            }
        }

        @if (online.user?.is_ultimate === false) {
            <app-games-trial-banner
                [applyBottomOffset]="true"
                [trialStatus]="{ games_played: freeMatchesService.gamesPlayed }">
            </app-games-trial-banner>
        }
    </div>
</div>

<ng-template #lobbyGame let-gameplay="gameplay" let-yourGame="yourGame">
    <div class="global-lobby-shadow col-span-1 flex flex-col rounded-md h-fit">
        <div class="flex flex-col space-y-2 rounded-t-md bg-game-card-top px-4 py-2.5">
            <div class="flex items-center justify-between w-full overflow-hidden">
                <div class="font-bold text-black truncate">{{ onlineFunctions.onlineGameplayName(gameplay) }}</div>

                @if (
                    (gameplay.players[0].room && !gameplay.cameraOnly && !gameplay.cameraOrOmniScoringOnly) ||
                    gameplay.cameraOnly ||
                    gameplay.ultimateOnly ||
                    gameplay.omniScoringOnly ||
                    gameplay.cameraOrOmniScoringOnly
                ) {
                    <div class="flex gap-2 flex-none h-6 max-h-6">
                        @if (gameplay.players[0].room && !gameplay.cameraOnly && !gameplay.cameraOrOmniScoringOnly) {
                            <div
                                class="flex items-center rounded-sm bg-panel dark:bg-black-full text-black p-1.5 cursor-pointer"
                                (click)="showGameInfo(gameplay)">
                                <app-icon icon="videocam" size="small"></app-icon>
                            </div>
                        }

                        @if (gameplay.cameraOnly || gameplay.ultimateOnly || gameplay.omniScoringOnly) {
                            <div
                                class="flex items-center rounded-sm bg-panel dark:bg-black-full text-black px-1"
                                (click)="gameplay.cameraOnly ? showGameInfo(gameplay) : null">
                                <div
                                    class="text-xxxs font-bold uppercase leading-none px-0.5"
                                    [translate]="'ONLY'"
                                    i18n="@@ONLY">
                                    Only
                                </div>

                                @if (gameplay.cameraOnly) {
                                    <app-icon class="px-0.5" icon="videocam" size="small"></app-icon>
                                }

                                @if (gameplay.ultimateOnly) {
                                    <app-icon class="scale-[0.80]" icon="premium" size="small"></app-icon>
                                }

                                @if (gameplay.omniScoringOnly) {
                                    <img class="w-12 px-0.5" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                                }
                            </div>
                        }

                        @if (gameplay.cameraOrOmniScoringOnly) {
                            <div class="flex items-center rounded-sm bg-panel dark:bg-black-full text-black px-1">
                                <app-icon class="px-0.5" icon="videocam" size="small"></app-icon>
                                <div
                                    class="text-xxxs font-bold uppercase leading-none mr-0.5"
                                    [translate]="'OR'"
                                    i18n="@@OR">
                                    Or
                                </div>
                                <img class="w-12 px-0.5" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                            </div>
                        }
                    </div>
                }
            </div>
        </div>

        <div class="flex w-full flex-col rounded-b-md bg-game-card-bottom px-4 py-2">
            @if (gameplay.players[0].has_omni || gameplay.players[0].room?.camType === 'smart_device') {
                <div class="mb-[0.675rem] flex justify-center items-center rounded-md bg-neutral-50 space-x-1.5">
                    @if (gameplay.players[0].has_omni) {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                    }
                    @if (gameplay.players[0].has_omni && gameplay.players[0].room?.camType === 'smart_device') {
                        <div class="text-black leading-none">+</div>
                    }
                    @if (gameplay.players[0].room?.camType === 'smart_device') {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/virt_cam_logo.webp'" />
                    }
                </div>
            }

            <div class="flex items-center justify-between space-x-2 overflow-hidden">
                <div
                    class="flex items-center justify-center space-x-2 overflow-hidden cursor-pointer text-black"
                    (click)="showPlayerInfo(gameplay.players[0])">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[0].is_ultimate"
                        [badge]="gameplay.players[0].badge"
                        [url]="gameplay.players[0].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div class="space-y-0.5 overflow-hidden">
                        <div class="flex items-center space-x-1">
                            <div class="ucfirst truncate text-xs font-bold leading-none">
                                {{ gameplay.players[0].first_name || ('NO_NAME' | translate) }}
                            </div>
                            @if (gameplay.players[0].countryFlag) {
                                <img class="h-2" src="assets/images/flags/rect/{{ gameplay.players[0].countryFlag }}" />
                            }
                        </div>
                        <app-online-player-stats [player]="gameplay.players[0]"></app-online-player-stats>
                    </div>
                </div>
                <div class="flex items-center space-x-2">
                    @if (yourGame) {
                        <div
                            class="rounded-full bg-black px-2 text-xxxs font-bold uppercase text-white whitespace-nowrap"
                            [translate]="'YOUR_GAME'"
                            i18n="@@YOUR_GAME">
                            Your game
                        </div>
                    }

                    @if (!yourGame && gameplay.doc_id !== dcInvites.currentOutgoingInvite()?.gameplay_doc_id) {
                        <app-primary-button
                            size="extrasmall"
                            color="orange"
                            (click)="showGameInfo(gameplay)"
                            [translate]="'JOIN_EXCLAMATION'"
                            i18n="@@JOIN_EXCLAMATION">
                            Join!
                        </app-primary-button>
                    } @else if (!yourGame && gameplay.doc_id === dcInvites.currentOutgoingInvite()?.gameplay_doc_id) {
                        <div class="flex items-center space-x-2 cursor-pointer" (click)="cancelJoin()">
                            <div class="h-full items-center rounded-sm bg-white px-2 py-1.5 text-black">
                                <div class="pt-0.5 font-bebas-neue text-xl leading-none">
                                    {{ dcInvites.showTimer() }}
                                </div>
                            </div>
                            <app-primary-button size="extrasmall" color="red" [translate]="'CANCEL'" i18n="@@CANCEL">
                                Cancel
                            </app-primary-button>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #liveGame let-gameplay="gameplay">
    <div class="global-lobby-shadow col-span-1 flex flex-col rounded-md h-fit">
        <div class="flex items-center justify-between rounded-t-md bg-game-card-top px-4 py-2.5">
            <div class="font-bold text-black">{{ onlineFunctions.publicOnlineGameplayName(gameplay) }}</div>
            @if (!gameplay.isFinished) {
                <app-primary-button
                    size="extrasmall"
                    color="orange"
                    (click)="watch(gameplay)"
                    [translate]="'WATCH_GAME'"
                    i18n="@@WATCH_GAME">
                    Watch game
                </app-primary-button>
            } @else {
                <app-primary-button size="extrasmall" color="red" [translate]="'ENDED'" i18n="@@ENDED">
                    Ended
                </app-primary-button>
            }
        </div>
        <div class="grid grid-cols-11 rounded-b-md bg-game-card-bottom px-4 py-2">
            @if (gameplay.has_omni || gameplay.has_virt_cam) {
                <div
                    class="col-span-11 mb-[0.675rem] flex justify-center items-center rounded-md bg-neutral-50 space-x-1.5">
                    @if (gameplay.has_omni) {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/omni_scoring_logo.webp'" />
                    }
                    @if (gameplay.has_omni && gameplay.has_virt_cam) {
                        <div class="text-black leading-none">+</div>
                    }
                    @if (gameplay.has_virt_cam) {
                        <img class="w-16 py-2" [src]="'assets/icon/ftr/virt_cam_logo.webp'" />
                    }
                </div>
            }

            <div
                class="col-span-4 flex items-center justify-start space-x-2 cursor-pointer"
                (click)="showPlayerInfo(gameplay.players[0])">
                <div class="relative">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[0].is_ultimate"
                        [badge]="gameplay.players[0].badge"
                        [url]="gameplay.players[0].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div
                        class="absolute -bottom-1 inline-flex h-4 w-4 items-center justify-center bg-white dark:bg-black-full text-black dark:text-white p-0.5"
                        [ngClass]="{ '-left-1': gameplay.players[0].badge, '-right-1': !gameplay.players[0].badge }">
                        @if (gameplay.players[0].room) {
                            <app-icon icon="videocam" size="small"></app-icon>
                        } @else {
                            <app-icon icon="videocam_off" size="small" class="opacity-50 text-black"></app-icon>
                        }
                    </div>
                </div>

                <div class="space-y-0.5 overflow-hidden">
                    <div class="flex items-center space-x-1">
                        <div class="ucfirst truncate text-xs font-bold leading-none text-black">
                            {{ gameplay.players[0].first_name || ('NO_NAME' | translate) }}
                        </div>
                        @if (gameplay.players[0].countryFlag) {
                            <img class="h-2" src="assets/images/flags/rect/{{ gameplay.players[0].countryFlag }}" />
                        }
                    </div>
                    <div class="flex items-center">
                        <app-online-player-stats [player]="gameplay.players[0]"></app-online-player-stats>
                    </div>
                </div>
            </div>

            <div class="col-span-3 flex items-center justify-center font-bebas-neue">
                <app-match-interim-score size="lobby" [type]="gameplay.has_sets ? 'sets' : 'legs'" [showLabel]="true">
                    {{ onlineFunctions.getPublicInterim(gameplay) }}
                </app-match-interim-score>
            </div>

            <div
                class="col-span-4 flex items-center justify-end space-x-2 cursor-pointer"
                (click)="showPlayerInfo(gameplay.players[1])">
                <div class="space-y-0.5 overflow-hidden">
                    <div class="flex items-center justify-end space-x-1">
                        @if (gameplay.players[1].countryFlag) {
                            <img class="h-2" src="assets/images/flags/rect/{{ gameplay.players[1].countryFlag }}" />
                        }
                        <div class="ucfirst truncate text-xs font-bold leading-none text-black">
                            {{ gameplay.players[1].first_name || ('NO_NAME' | translate) }}
                        </div>
                    </div>
                    <div class="flex items-center justify-end">
                        <app-online-player-stats [player]="gameplay.players[1]"></app-online-player-stats>
                    </div>
                </div>
                <div class="relative">
                    <app-profile-photo
                        class="h-fit"
                        [ultimate]="gameplay.players[1].is_ultimate"
                        [badge]="gameplay.players[1].badge"
                        [url]="gameplay.players[1].profile_photo_url"
                        [size]="32">
                    </app-profile-photo>
                    <div
                        class="absolute -bottom-1 -left-1 inline-flex h-4 w-4 items-center justify-center bg-white dark:bg-black-full text-black dark:text-white p-0.5">
                        @if (gameplay.players[1].room) {
                            <app-icon icon="videocam" size="small"></app-icon>
                        } @else {
                            <app-icon icon="videocam_off" size="small" class="opacity-50 text-black"></app-icon>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
